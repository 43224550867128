import { ICreateProject } from '@/entity/project/create-project';
import { IProject, IProjectBudgetAndCost } from '@/entity/project/project';
import { IProjectComment } from '@/entity/project/project-comment';
import { IBackgroundImage } from '@/entity/shared/background-image';
import { IObjectShort } from '@/entity/shared/object-short';
import ApiBase from '@api/api-base';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import { appTokenMgr } from '@t/employee-app-role';
import { IProjectSave, IProjectSaveContractData } from '@/entity/management/management-phase';

class ProjectApi extends ApiBase<IProject> {
    /**
     *
     */
    constructor(urlBase: string = 'api/projects') {
        super(urlBase);
    }

    public async createProject(data: ICreateProject): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ICreateProject>(this.url, data);
    }

    public async checkTrigram(data: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean>('get', this.url, {
            urlParameter: ['checktrigram'],
            queryParameter: { trigram: data },
            canBeCancelled: true
        });
    }

    public async checkStudioManagerUser(data: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean>('get', this.url, {
            urlParameter: ['checkstudiomanager'],
            queryParameter: { projectId: data },
            canBeCancelled: true
        });
    }

    public async getAllFilteredByCurrentManager(): Promise<ICancellableResult<IProject[]>> {
        const isAdmin: string = '' + appTokenMgr.isAdmin();
        return await ajaxCall.baseAjaxCall<IProject[]>('get', this.url, {
            urlParameter: ['filteredbycurrentmanager'],
            queryParameter: { isAdmin: isAdmin }
        });
    }

    public async getAllFilteredByCurrentManagerInStudio(): Promise<ICancellableResult<IProject[]>> {
        const isAdmin: string = '' + appTokenMgr.isAdmin();
        return await ajaxCall.baseAjaxCall<IProject[]>('get', this.url, {
            urlParameter: ['filteredbycurrentmanagerInStudio'],
            queryParameter: { isAdmin: isAdmin }
        });
    }

    public async getAllFilteredByCurrentBaseUser(): Promise<ICancellableResult<IProject[]>> {
        return await ajaxCall.baseAjaxCall<IProject[]>('get', this.url, {
            urlParameter: ['filteredbycurrentbaseuser']
        });
    }

    public async getAdvancement(data: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number>('get', this.url, {
            urlParameter: ['advancement'],
            queryParameter: { projectId: data },
            canBeCancelled: true
        });
    }

    public async getInvoicedTotal(data: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number>('get', this.url, {
            urlParameter: ['invoicedTotal'],
            queryParameter: { projectId: data },
            canBeCancelled: true
        });
    }

    public async getCommentsByProjectId(data: string): Promise<ICancellableResult<IProjectComment[]>> {
        return await ajaxCall.baseAjaxCall<IProjectComment[]>('get', this.url, { urlParameter: [data, 'comments'] });
    }

    public async submitComments(
        projectId: number,
        comments: IProjectComment[]
    ): Promise<ICancellableResult<IProjectComment[]>> {
        return await ajaxCall.baseAjaxCall<IProjectComment[], IProjectComment[]>('post', this.url, {
            urlParameter: [projectId + '', 'comments'],
            payload: comments
        });
    }

    public async getProjectIdList(data: number[]): Promise<ICancellableResult<IObjectShort[]>> {
        return await ajaxCall.post<IObjectShort[], number[]>(this.url + '/projectidlist', data);
    }

    public async addBackgroundImage(data: IBackgroundImage, projectId: number): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IBackgroundImage>('post', this.url, {
            urlParameter: [projectId + '', 'backgroundimage'],
            payload: data
        });
    }

    public async getAllBackgroundImages(): Promise<ICancellableResult<IBackgroundImage[]>> {
        return await ajaxCall.baseAjaxCall<IBackgroundImage[]>('get', this.url, { urlParameter: ['backgroundimages'] });
    }

    public async getBackgroundImageFromProject(projectId: number): Promise<ICancellableResult<IBackgroundImage>> {
        return await ajaxCall.baseAjaxCall<IBackgroundImage>('get', this.url, {
            urlParameter: [projectId + '', 'backgroundimage']
        });
    }

    public async getBudgetAndCost(projectId: number | null): Promise<ICancellableResult<Array<IProjectBudgetAndCost>>> {
        return await ajaxCall.baseAjaxCall<Array<IProjectBudgetAndCost>>('get', this.url, {
            urlParameter: ['BudgetAndCost', projectId?.toString() ?? '']
        });
    }

    public async exportProjectsData(
        data: number[],
        dataAddsColumns: string[],
        sortKeySelected: string,
        isSortDesc: boolean
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                ProjectIdList: data,
                AdditionalColumnsList: dataAddsColumns,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc
            },
            urlParameter: ['exportProjectsdata'],
            responseType: 'blob'
        });
    }

    public async GetListSave(projectId: number): Promise<ICancellableResult<IProjectSave[]>> {
        return await ajaxCall.baseAjaxCall<IProjectSave[]>('get', this.url, {
            urlParameter: [projectId + '', 'getListSave']
        });
    }

    public async SaveScreenshot(
        projectId: number,
        projectName: string,
        startDate: Date,
        endDate: Date,
        data: IProjectSaveContractData[]
    ) {
        return await ajaxCall.baseAjaxCall('post', this.url, {
            payload: {
                projectId: projectId,
                projectDesignation: projectName,
                startDate: startDate,
                endDate: endDate,
                contractData: data
            },
            urlParameter: ['saveProjectEtat']
        });
    }

    public async GetSaveDetail(
        projectId: number,
        projectSaveId: number
    ): Promise<ICancellableResult<IProjectSaveContractData[]>> {
        return await ajaxCall.baseAjaxCall('get', this.url, {
            queryParameter: {
                projectId: `${projectId}`,
                projectSaveId: `${projectSaveId}`
            },
            urlParameter: ['getSaveDetail']
        });
    }
}

export const projectApi: ProjectApi = new ProjectApi();
